import $ from 'jquery';
import Typed from 'typed.js';
import AOS from 'aos';

export default function () {
  const $headerCta = $('.header__cta-button');
  const $socials = $('.header__social-container');
  const $navTrigger = $('.nav__trigger');
  const $infoListButtons = $('.info-list > li');

  $headerCta.addClass('visible');
  $navTrigger.addClass('visible');
  $socials.fadeIn(450);

  new Typed('#typed', { // eslint-disable-line
    strings: [
      '<span class="offset">M</span><span class="offset">A</span><span class="offset">K</span><span class="offset">E</span><span class="offset">R</span><span class="offset">S</span>',
      '<span>D</span><span>E</span><span>N</span><span>K</span><span>E</span><span>R</span><span>S</span>',
      '<span>D</span><span>O</span><span>E</span><span>N</span><span>E</span><span>R</span><span>S</span>',
    ],
    typeSpeed: 30,
    backSpeed: 15,
    startDelay: 300,
    showCursor: false,
    loop: true,
    smartBackspace: false,
    onComplete: () => {

    },
  });

  $infoListButtons.each((index, element) => {
    const $element = $(element);

    $element.on('click mouseenter', () => {
      $infoListButtons.removeClass('active');
      window.setTimeout(() => $element.addClass('active'), 100);
    });
  });

  AOS.init();
}
