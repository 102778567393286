import nod from 'nod-validate';

export default function () {
  const myNod = nod();

  myNod.configure({
    submit: '.js-submit',
    disableSubmit: true,
  });

  myNod.add([{
    selector: '.js-required',
    validate: 'presence',
    errorMessage: '',
  }, {
    selector: '.js-number',
    validate: (callback, value) => {
      const number = parseInt(value, 10);
      callback(!isNaN(number)); // eslint-disable-line
    },
    errorMessage: 'Leeftijd moet een nummer zijn.',
  }]);
}
