import $ from 'jquery';
import 'fullpage.js/vendors/scrolloverflow';
import Fullpage from 'fullpage.js/dist/fullpage.extensions.min';
import MicroModal from 'micromodal';
import animations from './animations';

export default function () {
  const $headerCta = $('.header__cta-button');

  const fullpage = new Fullpage('#fullpage', { // eslint-disable-line
    licenseKey: 'A9871E14-B4F74D94-9F0A29A8-6ED1C4DA',
    autoScrolling: true,
    scrollHorizontally: true,
    fixedElements: '.header',
    scrollOverflow: true,
    responsiveWidth: 769,
    onLeave: (origin, destination) => {
      $('.modal__nav > ul > li').removeClass('active');
      $(`.modal__nav > ul > li:nth-child(${destination.index + 1})`).addClass('active');

      // $('.section [data-aos]').removeClass('aos-animate');

      window.setTimeout(() => {
        $headerCta.addClass('animate');
        window.setTimeout(() => {
          $headerCta.removeClass('animate');
        }, 400);
      }, 400);
    },
    onSlideLeave: () => {
      // $('.slide [data-aos]').removeClass('aos-animate');
    },
    afterSlideLoad: () => {
      // $('.slide.active [data-aos]').addClass('aos-animate');
    },
    afterRender: () => {
      animations();
      MicroModal.init({
        awaitCloseAnimation: true,
        onShow: () => {
          $('html').addClass('modal-open');
          fullpage.setAllowScrolling(false);
          fullpage.setKeyboardScrolling(false);
        },
        onClose: () => {
          $('html').removeClass('form-modal-open');
          $('html').removeClass('modal-open');
          fullpage.setAllowScrolling(true);
          fullpage.setKeyboardScrolling(true);
        },
      });
      // fade in first section image & start animation
    },
    afterLoad: (origin, destination) => {
      // $('.section.active [data-aos]').addClass('aos-animate');

      if (destination && destination.item) {
        const $section = $(destination.item);
        const $fadeItems = $section.find('.fade');
        $fadeItems.addClass('fade--visible');
      }
    },
  });

  $('.js-scroll-cta').on('click touch', () => {
    fullpage.moveTo(6);
    $('.js-scroll-cta').blur();
    $('.section--6').focus();
  });

  $('[data-anchor]').on('click touch', (e) => {
    const target = $(e.target).data().anchor;
    const $modal = $('#menu-modal');

    fullpage.moveTo(target);

    $modal.removeClass('is-open');
    $modal.attr('aria-hidden', 'true');
    $('html').removeClass('modal-open');
    fullpage.setAllowScrolling(true);
    fullpage.setKeyboardScrolling(true);
  });

  $('#form-modal .button').on('click touch', () => {
    fullpage.moveTo(1);
  });

  $('.modal--form .modal__overlay').on('click touch', () => {
    $('html').removeClass('form-modal-open');
    fullpage.moveTo(1);
  });
}
