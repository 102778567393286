import responsiveVideos from './components/layout/responsiveVideos';
import fullpage from './components/layout/fullpage';
import validation from './components/forms/validation';
import formResponse from './components/forms/formResponse';

require('./utils/nativeConsole');

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Layout setup
responsiveVideos();
fullpage();
validation();
formResponse();
