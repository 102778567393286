import MicroModal from 'micromodal';
import $ from 'jquery';

export default function () {
  window.formResponse = () => {
    MicroModal.show('form-modal');
    $('form input, form textarea').val('');
    $('html').addClass('form-modal-open');
  };
}
